// 定义内容
export default {
	goods: {
		vipValueUnitName: 'Months',
		timeValueUnitName: 'Minute',
		vipTypeName: 'VIP',
		timeTypeName: 'Video Time',
		goldTypeName: 'Gold',
		tables: {
			valid: 'Valid',
			timeLimited: 'Time Limited',
			cycleExpirationMinutes: 'Cycle Expiration Minutes',
			timeLimitedExpiration: 'Time-Limited Expiration',
			addGoodsBtn: 'Add',
			goodsName: 'Commodity name',
			goodsBase: 'Original price',
			goodsDiscount: 'Discount price',
			goodsType: 'Type',
			goodsValue: 'Value',
			goodsValueAddition: 'Value(Adds)',
			hot: 'Is hot',
			thindPartyId: 'Third party id',
			platformType: 'Platform type',
			appPlatform: 'app platform',
			createTime: 'Creation time',
			operation: 'Operation',
			editBtn: 'Edit',
			deleteBtn: 'Delete',
			first: 'Is First',
			level: 'Level',
			giveCoins: 'Give Coins Every Count',
			giveCoinsCount: 'Give Count',
			giveCoinsIntervalDays: 'Give Coins Interval Days',
		},
		form: {
			valid:'Is Valid',
			goodsName: 'Name',
			goodsBase: 'Original price',
			goodsDiscount: 'Discount price',
			goodsType: 'Type',
			goodsValue: 'Value',
			goodsValueAddition: 'Value(Addition)',
			hot: 'Is hot',
			first: 'Is First',
			timeLimited: 'Is Time Limited',
			cycleExpirationMinutes:'cycle Minutes',
			timeLimitedExpiration:'Expiration',
			level: 'Level',
			giveCoins: 'Give Coins Every Count',
			giveCoinsCount: 'Give Count',
			giveCoinsIntervalDays: 'Give Coins Interval Days',
			radioYes: 'Yes',
			radioNo: 'No',
			everyday:'everyday',
			minutes:'minute(s)',
			until:'until',
			submitBtnUpdate: 'Update',
			submitBtnAdd: 'Add',
			titleUpdate: 'Modify commodity',
			titleCopy: 'Copy commodity',
			titleAdd: 'Add commodity',
			validateGoodsDiscount: 'The discount price cannot be greater than the original price',
			validateGoodsDiscount1: '折扣价不能小于等于0',
			addGoodsFail: 'Failed to add commodity',
			updateGoodsFail: 'Failed to update commodity, There may be the same third-party product ID and platform type',
			deleteGoodsFail: 'Failed to delete commodity, There may be the same third-party product ID and platform type',
		},
		deleteConfirm: {
			msg: 'Are you sure to delete "{0}" commodity？',
			title: 'Delete',
			confirmBtn: 'Confirm',
			cancelBtn: 'Cancel'
		}
	}
};
