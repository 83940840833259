// 定义内容
export default {
	goods: {
		vipValueUnitName: '个月',
		timeValueUnitName: '分钟',
		vipTypeName: 'VIP',
		timeTypeName: '视频时长',
		goldTypeName: '金币',
		tables: {
			valid: '是否启用',
			timeLimited: '时间限制',
			cycleExpirationMinutes: '时间限制(分钟)',
			timeLimitedExpiration: '时间限制-到期时间',
			addGoodsBtn: '添加',
			goodsName: '商品名字',
			goodsBase: '原价',
			goodsDiscount: '折扣价',
			goodsType: '类型',
			goodsValue: '值',
			goodsValueAddition: '附加值',
			hot: '是否火热',
			thindPartyId: '三方商品ID',
			platformType: '平台类型',
			appPlatform: 'APP平台',
			createTime: '创建时间',
			operation: '操作',
			editBtn: '编辑',
			deleteBtn: '删除',
			first: '首充',
			level: '等级',
			giveCoins: '每次赠送金币',
			giveCoinsCount: '赠送几次',
			giveCoinsIntervalDays: '赠送每次间隔几天',
		},
		form: {
			valid: '是否启用',
			goodsName: '商品名字',
			goodsBase: '商品原价',
			goodsDiscount: '折扣价',
			goodsType: '商品类型',
			goodsValue: '商品值',
			goodsValueAddition: '商品附加值',
			hot: '是否火热',
			first: '首充',
			timeLimited: '时间限制',
			cycleExpirationMinutes: '时间限制(分钟)',
			timeLimitedExpiration: '时间限制-到期时间',
			level: '等级',
			giveCoins: '每次赠送金币',
			giveCoinsCount: '赠送几次',
			giveCoinsIntervalDays: '赠送每次间隔几天',
			radioYes: '是',
			radioNo: '否',
			everyday:'每天',
			minutes:'分钟',
			until:'直到',
			submitBtnUpdate: '修改',
			submitBtnAdd: '添加',
			titleUpdate: '修改商品',
			titleCopy: '复制商品',
			titleAdd: '添加商品',
			validateGoodsDiscount: '折扣价不能大于原价',
			validateGoodsDiscount1: '折扣价不能小于等于0',
			addGoodsFail: '添加商品失败, 可能存在相同的三方商品ID和平台类型',
			updateGoodsFail: '更新商品失败, 可能存在相同的三方商品ID和平台类型',
			deleteGoodsFail: '删除商品失败',
		},
		deleteConfirm: {
			msg: '确定删除 "{0}" 商品？',
			title: '删除',
			confirmBtn: '确定',
			cancelBtn: '取消',
		},
	},
};
